@import "~bootstrap/scss/functions";

@import "./colors.scss";

@import "./typography.scss";

@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/variables-dark";

// Include any default map overrides here

@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";

@import "~bootstrap/scss/utilities/api";

// Add additional custom code here

// Mixin
@mixin fontSize($size) {
  font-size: $size !important;

  @include media-breakpoint-down(lg) {
    font-size: calc(#{$size} - 1px);
  }

  @include media-breakpoint-down(md) {
    font-size: calc(#{$size} - 3px);
  }

  @include media-breakpoint-down(sm) {
    font-size: #{($size * 0.5)};
  }
}


// added below code to remove placeholder in safari browser - Kowshik
input[type="date"]::-webkit-datetime-edit-day-field,
input[type="date"]::-webkit-datetime-edit-month-field,
input[type="date"]::-webkit-datetime-edit-year-field,
input[type="date"]::-webkit-datetime-edit-text {
  color: #000000;
}

input[type="date"] {
  color: transparent;
}






.br-5 {
  border-radius: 5px !important;
}

.text-light-dark {
  color: $light-dark;
}

.margin-lg {
  @media only screen and (min-width: 1280px) {
    margin: 75px 65px !important;
  }
}

.fit-content {
  height: fit-content;
}

.case-table-container {
  max-height: 65vh;
  overflow-y: scroll;
}

.case-tr {
  background-color: $light-pink !important;

  border-bottom: 1px solid $text-underline !important;
}

.case-th {
  padding: 10px !important;
  border-bottom: none !important;
}

.case-tbody-tr:nth-child(odd) {
  background-color: white !important;
}

.case-tbody-tr:nth-child(even) {
  background-color: $light-pink !important;
}

.status-done {
  color: $status-success !important;
}

.status-progress {
  color: $status-warning !important;
}

.status-sent {
  color: $status-info !important;
}

.sidebar-item {
  .menu-active {
    color: $primary !important;
  }
  .menu-inactive {
    color: rgba(0, 0, 0, 0.54);
  }
}

// .table-pagination-wrapper{
//   position: absolute;
//   bottom: 0px;
//   left: 0px;
//   right: 0px;
//   padding: 20px;
//   z-index: 2;
//   background: white;
//  }

.bg-light-pink {
  background-color: $light-pink;
}

.bg-light-peach {
  background-color: $light-peach;
}

.text-brown {
  color: $light-brown;
}

.px-md {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.mb-md {
  margin-bottom: 35px !important;
}

.mx-md {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.bg-light-secondary {
  background-color: $light-secondary;
}

.dropDown-border {
  border: 1px solid $text-underline;
  border-top: 1px solid $text-underline;
  line-height: 0;
}

.outer-border {
  border: 1px solid $text-underline;
  border-top: 1px solid $text-underline;
  line-height: 0;
  text-align: left;
  margin-top: 20px;
  .label {
    background: white;
    padding: 7px;
    margin-left: 31px;
  }
  .inner-content {
    padding: 30px;
    line-height: normal;
  }
}

.stepper-indicator {
  border-radius: 50px;
  height: 4px;
  width: 65px;
  background-color: $light-blue1;
  &.active {
    background-color: $status-success;
  }
}

.activity-card-left-wrap {
  display: flex;

  .chat-wrapper {
    border: 2px solid #e5e5ff;
    border-radius: 0 5px 5px 5px;
    position: relative;
    background-color: #fff;
  }

  .arrow {
    position: relative;
    width: 0;
    height: 0;
    border-bottom: solid 10px transparent;
    border-right: solid 10px #e5e5ff;
    border-left: solid 10px transparent;
    border-top: solid 10px #e5e5ff;

    &:before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      border-bottom: solid 10px transparent;
      border-right: solid 10px #fff;
      border-left: solid 10px transparent;
      border-top: solid 10px #fff;
      top: -8px;
      left: -6px;
      z-index: 900;
    }
  }
}

.activity-card-right-wrap {
  display: flex;

  .chat-wrapper {
    border: 2px solid #e5e5ff;
    border-radius: 5px 0 5px 5px;
    position: relative;
    background-color: #ecf0f9;
  }

  .arrow {
    position: relative;
    width: 0;
    height: 0;
    border-bottom: solid 10px transparent;
    border-right: solid 10px transparent;
    border-left: solid 10px #e5e5ff;
    border-top: solid 10px #e5e5ff;

    &:before {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      border-bottom: solid 10px transparent;
      border-right: solid 10px transparent;
      border-left: solid 10px #ecf0f9;
      border-top: solid 10px #ecf0f9;
      top: -8px;
      right: -6px;
      z-index: 900;
    }
  }
}

.border-bottom-1 {
  border-bottom: 1px solid $text-underline !important;
}

.custom-link {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  transition: transform 0.3s ease;
}

.custom-link:hover {
  transform: translateY(-3px);
}

.custom-email {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  transition: transform 0.3s ease;
}

.custom-email:hover {
  text-decoration: underline;
}
